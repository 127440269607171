import { Menu, MenuItem, Button } from "@mui/material";
import { useCallback, useEffect, useState } from "react";
import { useContext } from "react";
import { AuthContext } from "../../services/context";
import { SurveyContext } from "../../views/survey";
import { questionTypes } from "../../utils/contentData";
const AddSurveyQuestion = ({
allQuestions,
setAllQuestions,
setTriggerScroll,
setNewAddedQuestionCode,
handleEdit
}) => {
  const [selectedAddQuestion,setSelectedAddQuestion] = useState(null);
  const [anchorEl, setAnchorEl] = useState(null);
  const isMenuOpen = Boolean(anchorEl);
  const {surveyService ,cache } = useContext(AuthContext);
  const { selectedSurvey,setSelectedSurvey} = useContext(SurveyContext)
  const allQuestionTypes = surveyService.getQuestionTypes();
  const [question, setQuestion] = useState(allQuestionTypes?.at(0)?.new());

  const handleButtonClick = (event) => {
    setAnchorEl(event.currentTarget);
  };

  const handleMenuClose = () => {
    setAnchorEl(null);
  };

  const handleTypeSelect = (type) => {
    handleSetQuestionType(type);
    handleMenuClose();
    setSelectedAddQuestion(type);
  };

  const handleSetQuestionType = (type) => {
    const questionType = surveyService.typeLookup(type);
    const questionNew = questionType.new(); 
    setQuestion({
      ...questionNew,
      type: questionType.type,
    });
  };

  const handleAddQuestion = useCallback(async () =>{
    const qnLength = allQuestions?.length;
    const stagedQuestion = {
      ...question,
        code:`Q${qnLength + 1}`,
        type:selectedAddQuestion,
        title:selectedAddQuestion,
        dependency:null
    }
    setAllQuestions((prev) => [...prev,stagedQuestion]);
    cache.set("questions", [...allQuestions, stagedQuestion]);

    // After adding questions -> prepare survey with added Questions

    let stagedSurvey = {
      ...selectedSurvey,
      questions: [...allQuestions, stagedQuestion],
    }
    await surveyService.surveyPrepare(stagedSurvey);
    setSelectedSurvey(stagedSurvey);

    // After Successfull question adddittion -> selected Question/Type -> null
    setSelectedAddQuestion(null)
    // Trgeer Scroll will trigger function in MUI Modal
    setTriggerScroll((prev) => !prev)
    // PURPOSE => make added survey-qn blink in Survey-question Table
    setNewAddedQuestionCode(stagedQuestion?.code)
    handleEdit(stagedQuestion)
  },[selectedAddQuestion]) 

  useEffect(() =>{
    if(selectedAddQuestion){
      handleAddQuestion();
    }
  },[handleAddQuestion])

  return (
    <div>
       <div id={"buttonQuestionAdd"}>
       <Button
            size="medium"
            onClick={handleButtonClick}
            variant="contained-primary"
            style={{
              borderRadius: "4px",
            }}
          >
            Add
          </Button>
       </div>
      <Menu
        anchorEl={anchorEl}
        open={isMenuOpen}
        onClose={handleMenuClose}
        MenuListProps={{
          "aria-labelledby": "add-question-button",
        }}
        sx={{
          marginLeft:" -115px",
          marginTop:" 5px",
      
        }}
      >
        {questionTypes.map((question) => (
          <MenuItem
            key={question?.type}
            onClick={() => handleTypeSelect(question?.type)}
            data-value={question?.type}
          >
           <div style={{
                display: "flex",
                gap: "10px"
                
           }}>
           <img src={question?.icon}/>
           <div>
           {question?.type}
           </div>
           </div>
          </MenuItem>
        ))}
      </Menu>
    </div>
  );
};

export default AddSurveyQuestion;
