import React, { forwardRef } from "react";
import { Editor } from "@tinymce/tinymce-react";
// import Input from "../Input/inputs";
import { API_KEY_TINY_MCE } from "../../utils/env";
import {
  TinyMCEEditorContentStyle,
  TinyMCEEditorPlugins,
  TinyMCEEditorToolbar,
} from "../../utils/contentData";
// import { TextareaAutosize } from "@mui/material";
import { TextareaAutosize } from "@mui/base/TextareaAutosize";
import * as utils from "../../services/utilities";

const CommonTextEditor = forwardRef(
  (
    {
      editorHeight = 400,
      value,
      onEditorChange,
      placeholder,
      inputName,
      inputOnChange,
      inputfullWidth,
      inputDefaultValue,
      inputProps = {
        style: { height: "130px", width: "100%", verticalAlign: "top" },
      },
      isTextEditorEnabled = true,
      error = null,
      helperTextMessage = "",
      onChangeTargetName = "instructions",
      plugins = TinyMCEEditorPlugins,
      toolbar = TinyMCEEditorToolbar,
      contentStyle = TinyMCEEditorContentStyle,
    },
    ref
  ) => {
    utils.log.info("::: VALUE IN TINY_MCE_EDITOR", value, isTextEditorEnabled);
    return isTextEditorEnabled == "true" ? (
      <div>
        <Editor
          apiKey={API_KEY_TINY_MCE}
          onInit={(evt, editor) => {
            if (ref) ref.current = editor;
          }}
          // initialValue={value}
          value={value}
          onEditorChange={(content) => {
            const event = {
              target: {
                name: onChangeTargetName,
                value: content,
              },
            };
            if (onEditorChange) onEditorChange(event);
          }}
          error={error}
          helperText={helperTextMessage}
          init={{
            height: editorHeight,
            menubar: false,
            branding: false,
            plugins,
            toolbar,
            content_style: contentStyle,
          }}
        />
      </div>
    ) : (
      <div style={{ width: "100%" }}>
        <TextareaAutosize
          style={{
            width: "100%",
            resize: "none",
            minHeight: "130px",
            padding: "10px",
          }}
          placeholder={placeholder}
          fullWidth={inputfullWidth}
          name={inputName}
          onChange={inputOnChange}
          defaultValue={inputDefaultValue}
          value={value}
          InputProps={{ ...inputProps }}
        />
      </div>
    );
  }
);

export default CommonTextEditor;
