// TODO: 052823 DEPRECATE FormUtility in favor of cache.js going forward.
/*eslint-disable*/

import { FormHelperText, Grid, TextField, Typography } from "@mui/material";
import React, { useContext, useState } from "react";
import Input from "../../components/Input/inputs";
import FormSelect from "../../components/Select";
import { AuthContext } from "../../services/context";
import FormUtility from "../../services/form";
import { initValueRangeObject, updateQuestionAnswer } from "../../services/question";
import * as utils from "../../services/utilities";
import { QuestionHeader } from "../recipients/question";
import * as dependencyUtil from "./dependency";
import errorIcon from "../../assets/errorIcons.svg";
// import useMediaQuery from "../../hooks/useMediaQuery";
const formUtility = new FormUtility();
export const comparators = [
  "None",
  "equals",
  "less than",
  "greater than",
  "between", // TODO: 062423 - Need to implement UI
  "even", 
  "odd", 
  "prime number",
];

const disableValueComparator = ["None","even","odd", "prime number","between"]
const listComparators = utils.toSelectItem(comparators);
export function New() {
  // utils.log.component("NumericInput.New()");
  return {
    type: "NumericInput",
    code: null,
    title: "New Numeric Input Question",
    description: "",
    instructions: "",
    dependency: null,
    answer: null,
    min: 1,
    max: 10,
  };
}
export function Reset(question) {
  // PURPOSE: Reset properties specific to this question type.
  // utils.log.component(`NumericInput.Reset(${question.code})`, question);
  utils.assert(question != null, "question is null.");
  question.answer = null;
}
export function Edit({ question, setInputs, enqueueSnackbar }) {
  // utils.log.component(`NumericInput.Edit(${question.code})`, question);
  // #region Assertions
  utils.assert(question != null, "question is null.");
  // #endregion Assertions
  // #region Initialize
  question.min = question.min < 1 ? 1 : question.min;
  question.max = question.max < 1 ? 10 : question.max;
  formUtility.setDetail(question);

  // #endregion
  // #region Events

  const handleMinChange = (e) => {
    // debugger;
    const value = e.target.value;
    if (isNaN(value))
      // Non-numeric value
      enqueueSnackbar("Minimum must be a number.", { variant: "error" });
    else if (value > question.max) {
      // Valid value
      return enqueueSnackbar("Minimum must be less than maximum.", {
        variant: "error",
      });
    }
    question.min = Number(value);
  };
  const handleMaxChange = (e) => {
    const value = e.target.value;
    const min = question.min;

    if (isNaN(value))
      // Non-numeric value
      enqueueSnackbar("Maximum must be a number.", { variant: "error" });
    else if (value < min) {
      // Greater than max
      enqueueSnackbar("Maximum must be greater than minimum.", {
        variant: "error",
      });
      return null;
    }
    // Valid value
    else {
      question.maxChoices = Number(value);
    }

    question.maxChoices = Number(value);
  };

  const handleChange = (e) => {
    if (e.target.name === "min") {
      if (e.target.value >= Number(question?.max)) {
        return enqueueSnackbar(
          "Minimum must be smaller than or euqal to Maximum",
          {
            variant: "error",
          }
        );
      }
    }
    if (e.target.name === "max") {
      if (e.target.value < Number(question?.min)) {
        return enqueueSnackbar(
          "Maxmimum must be greater than or euqal to Minimum",
          {
            variant: "error",
          }
        );
      }
    }
    formUtility.handleChange(e, setInputs);
  };
  return (
    <>
      <Grid container spacing={2}>
        <Grid item xs={12}>
          <Typography
            id="non-linear-slider"
            gutterBottom
            style={{ fontFamily: "Public-sans" }}
          >
            Range:
          </Typography>
        </Grid>
        <Grid item xs={12} sm={6} >
          <Input
            label="Minimum"
            fullWidth
            name="min"
            type={"number"}
            // onChange={(event) => formUtility.handleChange(event, setInputs)}
            onChange={handleChange}
            onBlur={handleMinChange}
            value={formUtility.getValue("min")}
            id="textboxMinimum"
          />
        </Grid>
        <Grid item xs={12} sm={6} >
          <Input
            label="Maximum"
            fullWidth
            name="max"
            type={"number"}
            id="textboxMaximum"
            // onChange={(event) => formUtility.handleChange(event, setInputs)}
            onChange={handleChange}
            onBlur={handleMaxChange}
            value={formUtility.getValue("max")}
          />
        </Grid>
      </Grid>
    </>
  );
}
export const DependencySelector = ({ context }) => {
  // !NOTE: useState() is prohibited in this component.
  utils.log.info(":: context",context);
  return (
    <>
      <DependencySelectorComparatorValue
        id="value"
        label="Value"
        context={context}
     />
    </>
  );
};
export const DependencySelectorComparatorValue = ({ id, label, context }) => {

  const { cache, error } = useContext(AuthContext);
  const dependency = dependencyUtil.get(context);
  // const {  question } = dependency || {};
  if (dependency != null && dependency[id] == null)
    // Add a value if it doesn't ex ist
    dependency[id] = null;
  const comparatorId = `${id}-comparator`;

  // Add None as default Comparator
  if( dependency != null &&   !dependency[comparatorId]){
    dependency[comparatorId] = "None";
    dependencyUtil.set(context, dependency);
  }
  const [selectedComparator,setSelectedComparator] = useState(dependency[comparatorId] ?? listComparators[0].value)
  const [errorObject, setErrorObject] = useState(null);
  
  let valueObject = {
    label: label,
    defaultValue: dependency[id] ?? cache.get(id) ?? null,
    error: errorObject,
  };

  const handleComparatorChange = (e) => {
    const dependency = dependencyUtil.get(context);
    const comparator = e.target.value;
    dependency[comparatorId] = comparator;
    dependencyUtil.set(context, dependency);
    setSelectedComparator(comparator);
  };

  const handleValueChange = (e) => {
    const value = e.target.value;
    if (value === "" || isNaN(value)) {
      // Invalid number
      error.setErrorObject(id, true, "Value is required.");
    } else {
      // Valid number
      dependency[id] = value;
      error.clearErrorObject(id);
    }
    setErrorObject(error.getErrorObject(id));
  };

  utils.log.info("^^ dependency in Numeric Input",{dependency});
  // #endregion
  return (
    <>
        <FormSelect
          id={`numericInput-${id}-comparators`}
          // id="dropdownComparators"
          label="Comparators"
          data={listComparators}
          value={selectedComparator}
          style={{ width: "120px" }}
          onChange={handleComparatorChange}
          debug
      />

      {
        selectedComparator === "between" && (
          <ComparatorBetween
            context={context}
            id={id}
          />
        )
      }
       <div>
       {!disableValueComparator?.includes(selectedComparator) && 
        <div id={id === "value" ? "dropdownStart" : id === "startValue" ? "dropdownStart" : "dropdownEnd"}>
          <Input
          id={`numericInput-${id}-value`}
          label={valueObject.label}
          name={id}
          fullWidth
          type={"number"}
          hideNumberSpinner
          style={{ width: "120px" }}
          onChange={(event) => handleValueChange(event)}
          defaultValue={valueObject.defaultValue}
          onFocus={(e) => e.currentTarget.select()}
          error={valueObject.error?.state}
          helperText={valueObject.error?.message}
        />
        </div>
        }
       </div>
    </>
  );
};

const ComparatorBetween = ({context , id}) => {
  // get dependency from Context
  const dependency = dependencyUtil.get(context);
  // set min and max in value for between
  if (typeof dependency.value !== "object" || !dependency.value) {
    dependency.value = initValueRangeObject();
    dependencyUtil.set(context, dependency);
  }

  const {question , value} = dependency || {};

  const [minValue,setMinValue] = useState(value?.min ? Number(value?.min) : Number(question?.min));
  const [maxValue,setMaxValue] = useState(value?.max ? Number(value?.max) : Number(question?.max));


  const handleValueChange = (e,type) => {
    const updatedValue = e.target.value;
    dependency.value[type] = updatedValue;
    dependencyUtil.set(context, dependency);
    if(type === "min"){
      setMinValue(updatedValue);
    }
    if(type === "max"){
      setMaxValue(updatedValue);
    }
  }

  return(
    <>
     <Input
         id={`numericInput-${id}-value`}
          label={"Minimum"}
          name={"min"}
          fullWidth
          type={"number"}
          hideNumberSpinner
          style={{ width: "120px" }}
          onChange={(event) => handleValueChange(event,"min")}
          value={minValue}
          
      />
      <Input
          id={`numericInput-${id}-value`}
          label={"Maximum"}
          name={"max"}
          fullWidth
          type={"number"}
          hideNumberSpinner
          style={{ width: "120px" }}
          onChange={(event) => handleValueChange(event,"max")}
          value={maxValue}
          
      />
    </>
  )
}


export function DependencyNew(question, choice) {
  const dependency = dependencyUtil.createBase(question);
  // Add value property to dependency
  dependency.comparator = comparators[0];
  dependency.value = null;
  return dependency;
}
export function Render(props) {
  // utils.log.component("NumericInput.Render()");
  // #region Initialize
  const question = props.question;
  const preview = props.preview ?? false;
  //when form is render initiall answer is not present in qun.So,we set qun.min to answer becaues we apply error on answer
  const answer = question.answer ?? question.min;
  const minValue = question.min ?? 0;
  const maxValue = question.max ?? 0;

  const validationMessage =
    answer < minValue || answer > maxValue
      ? `Value must be between ${minValue} and ${maxValue}.`
      : "";
  // #endregion
  // #region Events
  const handleChange = (e) => {
    let value = Number(e.target.value);
    // utils.log.event(`handleChange(e): ${value}`);
    updateQuestionAnswer(question, value, preview, props.setQuestions);
  };
  const isQuestionCompleted = isCompleted(question);

  // const {isSmDown} = useMediaQuery();

  // #endregion
  return (
    <>
      <div
        key={props.index}
        style={{
          ...props.rowStyle,
          border: isQuestionCompleted
            ? "1px solid #3BDB41"
            : "1px solid #E9E9E9",
        }}
      >
        {!preview && (
          <div style={{ fontSize: "18px", fontWeight: "700" }}>
            {question.code}
          </div>
        )}
        <div style={{ width: "100%", padding: preview ? "30px" : 0 }}>
          <QuestionHeader question={question}></QuestionHeader>
          {/* <div>{helperText}</div> */}
          <TextField
            id="numericInput-value"
            key={`numericInput-value.${question.code}`}
            label={`Enter value Range(${minValue} to ${maxValue})`}
            name="value"
            style={{
              // width: isSmDown ? "100%" : "300px",
              width: "300px",
              background: "#F8F8F8",
            }}
            type="number"
            inputProps={{
              inputMode: "numeric",
              pattern: "[0-9]*",
              // min: minValue, // BUG: 062623 min/max not being enforced.
              // max: maxValue,
            }}
            onChange={handleChange}
            // helperText={`${minValue} to ${maxValue}`}
            placeholder={`Enter value Range(${minValue} to ${maxValue})`}
            fullWidth
          ></TextField>
        </div>
      </div>
      {validationMessage && (
        <div
          style={{
            display: "flex",
            justifyContent: "end",
            alignItems: "center",
            gap: "10px",
            marginTop: preview ? 0 : "-20px",
          }}
        >
          <img
            src={errorIcon}
            alt="error-icon"
            height={"15px"}
            width={"15px"}
          />
          <FormHelperText style={{ color: "#DB3B3B" }}>
            {validationMessage}
          </FormHelperText>
        </div>
      )}
    </>
  );
}
// #region Dependency

// PURPOSE: Determine if dependency is satisfied.
export function isAnswered(dependency) {
  utils.log.info("^^ depdendency",dependency);
  if (!dependency || !dependency["value-comparator"]) {
    return false;
  }

  const { question } = dependency;
  if (!question || question?.answer == null) {
    return false;
  }

  const min = Number(question?.min);
  const max = Number(question?.max);
  const answer = Number(question?.answer);
  const comparatorValue = dependency.value != null ? Number(dependency.value) : null;

  if (isNaN(answer)) {
    return false;
  }

  if (
    !isNaN(min) &&
    !isNaN(max) &&
    (answer < min || answer > max)
  ) {
    return false;
  }

  utils.log.info("^^ numericDependency", dependency);

  switch (dependency["value-comparator"]) {
    case "None":
      return true;
    case "equals":
      return answer === comparatorValue;
    case "less than":
      return answer < comparatorValue;
    case "greater than":
      return answer > comparatorValue;
    case "between":
      return handleComparatorBetween(answer , dependency);
    case "even":
      return answer % 2 === 0;
    case "odd":
      return answer % 2 !== 0;
    case "prime number":
      return isPrime(answer);
    default:
      throw new Error(
        `isAnswered(${question.code}) - Unknown comparator: ${dependency["value-comparator"]}`
      );
  }
}

function handleComparatorBetween(answer ,dependency) {
  const {question , value} = dependency || {}
  return Number(question?.answer) >= Number(value?.min) && Number(question?.answer) <= Number(value?.max);
}

export function isPrime(number) {
  if (number <= 1) {
    return false;
  }
  for (let divisor = 2; divisor <= Math.sqrt(number); divisor++) {
    if (number % divisor === 0) {
      return false;
    }
  }
  return true;
}


export const isCompleted = (question) => {
  if (!question) {
    return false;
  }
  // check for min and max
  return Number(question.answer) >= Number(question.min) && Number(question.answer) <= Number(question.max);
};

export const getValue = (question) => {
  return {
    value: question.answer,
  };
};

// #endregion
/*
FIXED

*/
