// TODO: 102323 Implement Merge Fields INSERT button at cursor position in Subject or Body template
import { useEffect, useState, useRef, useContext } from "react";
import FormSelect from "../../../components/Select";
import Grid from "../../../components/Grid";
import Input from "../../../components/Input/inputs";
import * as utils from "../../../services/utilities";
import CommonTextEditor from "../../../components/Editor";
import { AuthContext } from "../../../services/context";

export default function Emails({ survey }) {
  utils.log.component("Emails", survey);
  // #region Assertions
  // #endregion
  // #region Functions
  // #endregion
  // #region Initialize
  const editorRef = useRef(null);
  const messageTypes = [
    "Invitation",
    "Reminder",
    "Followup",
    "Exemption",
    "Completed",
  ];
  //const messageTypes = survey.messageTemplates.map((t) => t.code);
  const mergeFields = [
    { label: "First name", value: "recipient.firstName" },
    { label: "Last name", value: "recipient.lastName" },
    { label: "UID", value: "recipient.uid" },
    { label: "Survey.Start", value: "survey.effectiveDate" },
    { label: "Survey.End", value: "survey.expireDate" },
  ];

  const messageTypesList = utils.toSelectItem(messageTypes);
  const [messageTemplate, setMessageTemplate] = useState(
    survey?.messageTemplates[0]?.code
  );
  const code = survey?.messageTemplates[0]?.code;
  const result = code ? code.charAt(0).toUpperCase() + code.slice(1) : "";
  const [selectedMessageType, setSelectedMessageType] = useState(result);
  const [showMergeField, setShowMergeField] = useState(false);
  const [selectedMergeField, setSelectedMergeField] = useState(null);

  // #endregion
  // #region Events

  const {settings} = useContext(AuthContext);
  useEffect(() => {
    utils.log.useEffect("Initialize", messageTemplate);
  }, []);
  const handleMessageType = (e) => {
    e.preventDefault();
    const messageType = e.target.value;

    // utils.log.info(`MessageTemplateType(selected): ${messageType}`);
    const template = survey.messageTemplates.find(
      (t) => t.code.toUpperCase() === messageType.toUpperCase()
    );
    setShowMergeField(true);
    setSelectedMessageType(messageType);
    //// utils.log.debug(`MessageTemplate: ${messageType}`, template);
    /*     cache.set("subject", template.subject);
    cache.set("body", template.body); */
    setMessageTemplate(template);
    // utils.log.stateChange(`setMessageTemplate(${messageType})`, template);
  };
  const handleChange = (e) => {
    const target = e.target.name;
    const value = e.target.value;
    // // utils.log.info(
    //   `MessageTemplate.${target}(changed): ${value}`,
    //   messageTemplate
    // );

    // Create a new object that's a copy of the current state
    const updatedMessageTemplate = { ...messageTemplate, [target]: value };

    // Update the state with the new object
    setMessageTemplate(updatedMessageTemplate);
    // utils.log.stateChange(
    //   `setMessageTemplate(messageTemplate)`,
    //   updatedMessageTemplate
    // );

    const template = survey.messageTemplates.find(
      (t) => t.code.toUpperCase() === messageTemplate.code.toUpperCase()
    );
    template.subject = updatedMessageTemplate.subject;
    template.body = updatedMessageTemplate.body;
  };

  const isTextEditorEnabled = Boolean(settings.find((s) => s.key === "IsTextEditorEnabled")?.value);

  // #endregion

  return (
    <div
      style={{
        boxShadow: "0px 1px 2px 0px #A4ACB933",
        background: "#FFFFFF",
        border: "1px solid #E1E1E1",
        borderRadius: "6px",
      }}
    >
      <Grid container sx={{ padding: "15px 20px" }}>
        <Grid
          item
          xs={12}
          sx={{ fontWeight: 600, fontSize: "16px", lineHeight: "20px" }}
        >
          Manage Email Templates
        </Grid>
      </Grid>
      <hr style={{ margin: "0px" }} />

      <Grid container spacing={3} sx={{ padding: "15px 20px" }}>
        <Grid item xs={12} sm={6}>
          <FormSelect
            debug
            label="Message Type"
            fullWidth
            data={messageTypesList}
            defaultValue={selectedMessageType}
            value={selectedMessageType}
            onChange={handleMessageType}
          ></FormSelect>
        </Grid>

        {showMergeField && (
          <Grid item xs={12} sm={6}>
            <FormSelect
              label="Merge Fields"
              data={mergeFields}
              defaultValue={mergeFields[0].value}
              value={selectedMergeField}
              onChange={(event) => {
                setSelectedMergeField(event.target.value);
              }}
              fullWidth
            ></FormSelect>
          </Grid>
        )}
        {selectedMergeField && (
          <Grid item xs={12}>
            <Input
              name="subject"
              label="Subject template"
              fullWidth
              onChange={handleChange}
              value={messageTemplate.subject}
            ></Input>
          </Grid>
        )}
        {selectedMergeField && (
          <Grid item xs={12}>
            <div
                style={{
                  width: "100%",
                  border: "0.64px solid #1111111A",
                  borderRadius: "6px",
                  boxShadow: "0px 1px 2px 0px #A4ACB933",
                  background: "#FBFBFB",
                  marginTop: "10px",
                  padding:"20px"
                }}
        >
            <CommonTextEditor
              ref={editorRef}
              onEditorChange={handleChange}
              onChangeTargetName="body"
              value={messageTemplate.body}
              editorHeight={"250"}
              isTextEditorEnabled={isTextEditorEnabled}
              placeholder={"Description"}
            />
            </div>
            {
              // <Editor
              // TODO: 051323 Complete implementation of image insert
              // BUG: 111723 https://github.com/GoPassGoNetworks/AVR/issues/31
              // apiKey={API_KEY_TINY_MCE}
              // onInit={(evt, editor) => {
              //   editorRef.current = editor;
              // }}
              // value={messageTemplate.body}
              // onEditorChange={(content, editor) => {
              //   const event = {
              //     target: {
              //       name: "body",
              //       value: content,
              //     },
              //   };
              //   handleChange(event);
              // }}
              // init={{
              //   height: "250",
              //   menubar: false,
              //   branding: false,
              //   plugins: [
              //     "advlist autolink lists link image charmap print preview anchor",
              //     "searchreplace visualblocks code fullscreen",
              //     "insertdatetime media table paste code help wordcount textcolor",
              //   ],
              //   toolbar:
              //     "undo redo | formatselect | image | " +
              //     "bold italic | forecolor backcolor | alignleft aligncenter " +
              //     "alignright alignjustify | bullist numlist outdent indent | " +
              //     "removeformat | help",
              //   content_style:
              //     "body { font-family:Public-sans,sans-serif; font-size:14px }",
              // }}
              // />
            }
          </Grid>
        )}
      </Grid>
    </div>
  );
}
