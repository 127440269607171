import { getApiOrigin } from "./context";

export default class SettingsService {
  constructor(headers) {
    this.headers = headers;
  }

  async getAll() {
    const remoteUrl = `${getApiOrigin()}/setting/GetAll`;

    return await fetch(remoteUrl, {
      method: "GET",
      headers: this.headers,
    });
  }
  async get(key,scope) {
    const remoteUrl = `${getApiOrigin()}/setting/${key}/scope/${scope}`;

    return await fetch(remoteUrl, {
      method: "GET",
      headers: this.headers,
    });
  }
}
