// TODO: 052823 DEPRECATE FormUtility in favor of cache.js going forward.
/*eslint-disable*/
import React from "react";
import { AdapterDayjs } from "@mui/x-date-pickers/AdapterDayjs";
import dayjs from "dayjs";
import { LocalizationProvider } from "@mui/x-date-pickers/LocalizationProvider";
import { DatePicker } from "@mui/x-date-pickers/DatePicker";
import { DatePicker as DatePickerAntD } from "antd";
import FormSelect from "../../components/Select";
import Input from "../../components/Input/inputs";
import { Typography, Grid } from "@mui/material";
import { QuestionHeader } from "../recipients/question";
import * as config from "../../services/config";
import { valueConvertByComparator, ComparatorBetween } from "./dateInput";
import * as utils from "../../services/utilities";
import {
  updateQuestionAnswer,
  initAnswerObject,
} from "../../services/question";
import * as dependencyUtil from "./dependency";
import FormUtility from "../../services/form";
import useMediaQuery from "../../hooks/useMediaQuery";
import { useState } from "react";

const formUtility = new FormUtility();

export const comparators = [
  "None",
  "equals",
  "not",
  "before",
  "after",
  "between",
  "weekday",
  "weekend",
  //"length", // TODO: 070623 Implement length comparator
];

export const comparatorsWithoutValue = ["Nones",  "weekday", "weekend"];
export function New() {
  return {
    type: "DateInputRange",
    code: null,
    title: "New Date Input (Range) Question",
    description: "",
    instructions: "",
    dependency: null,
    answer: initAnswerObject(),
    min: dayjs(),
    max: dayjs().add(1, "day"),
  };
}
export function Reset(question) {
  // PURPOSE: Reset user answers specific to this question type.
  utils.assert(question != null, "question is null.");
  question.answer = initAnswerObject();
}
export function Edit({ question, setInputs, enqueueSnackbar }) {
  // utils.log.component(`DateInputRange.Edit(${question.code})`, question);
  utils.assert(question != null, "question is null.");
  // #region Initialize
  formUtility.setDetail(question);

  const disablePastDates = (date) => dayjs(date).isBefore(question.min, "day");

  question.min = utils.checkDateValidity(
    `question(${question.code}).min`,
    dayjs(question.min).format(config.defaults.DATE_STORAGE_FORMAT),
    dayjs().format(config.defaults.DATE_STORAGE_FORMAT)
  );

  const disableFutureDates = (date) => dayjs(date).isAfter(question.max, "day");

  question.max = utils.checkDateValidity(
    `question(${question.code}).max`,
    dayjs(question.max).format(config.defaults.DATE_STORAGE_FORMAT),
    dayjs().add(1, "day").format(config.defaults.DATE_STORAGE_FORMAT)
  );

  /*   // Adjust min/max
  question.min = utils.adjustDate(question.max, question.min, "up").min;
  question.max = utils.adjustDate(question.min, question.max, "down").max; */
  // #endregion
  // #region Events
  const handleChange = (e, target) => {
    // NOTE: target = The question.property to update
    // Valid value
    const isoDate = dayjs(e.$d).format(config.defaults.DATE_STORAGE_FORMAT);
    question[target] = isoDate; //value.toLocaleDateString();
    // Adjust min/max
    // const targetOpposite = target === "min" ? "max" : "min";
    // const mode = target === "min" ? "up" : "down";

    // utils.log.info(":: handleChange in Date Range Picker()",{isoDate,target ,targetOpposite , mode})

    // question[targetOpposite] = utils.adjustDate(
    //   dayjs(question.min).format(config.defaults.DATE_STORAGE_FORMAT),
    //   dayjs(question.max).format(config.defaults.DATE_STORAGE_FORMAT),
    //   mode
    // )[targetOpposite];

    // Update question
    const event = {
      target: { name: target, value: isoDate },
    };
    formUtility.handleChange(event, setInputs);
  };
  // #endregion

  return (
    <Grid container spacing={2}>
      <Grid item xs={12}>
        <Typography
          id="non-linear-slider"
          gutterBottom
          style={{ fontFamily: "Public-sans" }}
        >
          Range:
        </Typography>
      </Grid>
      <Grid item xs={12} sm={6}>
        <LocalizationProvider dateAdapter={AdapterDayjs}>
          <DatePicker
            renderInput={(props) => <Input {...props} />}
            slotProps={{textField:{id:"textboxMinimum"}}}
            value={question.min}
            label="Minimum"
            onChange={(e) => handleChange(e, "min")}
            shouldDisableDate={disableFutureDates}
            sx={{
              button: {
                "&:focus": {
                  outline: "none",
                },
              },
            }}
          />
        </LocalizationProvider>
      </Grid>
      <Grid item xs={12} sm={6}>
        <LocalizationProvider dateAdapter={AdapterDayjs}>
          <DatePicker
            renderInput={(props) => <Input {...props} />}
            slotProps={{textField:{id:"textboxMaximum"}}}
            value={question.max}
            label="Maximum Date"
            onChange={(e) => handleChange(e, "max")}
            shouldDisableDate={disablePastDates}
            sx={{
              button: {
                "&:focus": {
                  outline: "none",
                },
              },
            }}
          />
        </LocalizationProvider>
      </Grid>
    </Grid>
  );
}
//export const comparators = ["equals", "contains", "startsWith", "endsWith"];
export const DependencySelector = ({context}) => {
  // !NOTE: useState() is prohibited in this component.
  //STEP:1 => Get Dependency => that is on which qn(single choice,multiple choice) it is dependent;
  return(
    <DependencySelectorComparatorValue
    context={context}
    />
  )
};

const DependencySelectorComparatorValue = ({ context }) => {
  const dependency = dependencyUtil.get(context);
  const { question = {}, value, comparator = "None" } = dependency || {};
  const { min, max } = question;

  // State initialization
  const [selectedComparator, setSelectedComparator] = useState(comparator);
  const [selectedValue, setSelectedValue] = useState(value ? dayjs(value) : null);

  // Update dependency with defaults if necessary
  if (!dependency.comparator) {
    dependency.comparator = "None";
    dependency.value = null;
    dependencyUtil.set(context, dependency);
  }

  // Handlers
  const handleComparatorChange = (e) => {
    const newComparator = e.target.value;
    setSelectedComparator(newComparator);
    if (newComparator === "None") setSelectedValue(null);

    dependency.comparator = newComparator;
    if (newComparator === "None") dependency.value = null;
    dependencyUtil.set(context, dependency);
  };

  const handleValueChange = (date) => {
    const formattedDate = date?.format(config.defaults.DATE_STORAGE_FORMAT);
    setSelectedValue(date);
    dependency.value = formattedDate;
    dependencyUtil.set(context, dependency);
  };

  // Comparator options
  const comparatorOptions = utils.toSelectItem(comparators);

  return (
    <>
      <FormSelect
        id="dropdownComparators"
        label="Comparators"
        data={comparatorOptions}
        value={selectedComparator}
        onChange={handleComparatorChange}
        style={{ width: utils.getTextBoxWidth(selectedComparator?.length) }}
      />

      {selectedComparator !== "None" && (
        <LocalizationProvider dateAdapter={AdapterDayjs}>
          {(selectedComparator === "between" || selectedComparator === "equals") ? (
            <ComparatorBetween
              context={context}
              minDate={dayjs(min)}
              maxDate={dayjs(max)}
            />
          ) : (
            !comparatorsWithoutValue.includes(selectedComparator) && (
              <DatePicker
                renderInput={(props) => (
                  <Input style={{ width: "150px" }} {...props} />
                )}
                label="Value"
                value={selectedValue}
                onChange={handleValueChange}
                minDate={dayjs(min)}
                maxDate={dayjs(max)}
              />
            )
          )}
        </LocalizationProvider>
      )}
    </>
  );
};

export function DependencyNew(question, choice) {
  const dependency = dependencyUtil.createBase(question);
  // Add value property to dependency
  dependency.value = null;
  return dependency;
}
export function Render(props) {
  // utils.log.component("DateInputRange.Render()", props);
  // #region Initialize
  const question = props.question;
  utils.assert(question != null, "question is null.");
  const preview = props.preview ?? false;
  const displayDateFormat = config.defaults.DATE_DISPLAY_FORMAT;
  question.answer ??= initAnswerObject();
  let minDate = (question.min = utils.checkDateValidity(
    `question(${question.code}).min`,
    dayjs(question.min).format(config.defaults.DATE_STORAGE_FORMAT),
    dayjs().format(config.defaults.DATE_STORAGE_FORMAT)
  ));
  let maxDate = (question.max = utils.checkDateValidity(
    `question(${question.code}).max`,
    dayjs(question.max).format(config.defaults.DATE_STORAGE_FORMAT),
    dayjs().add(1, "day").format(config.defaults.DATE_STORAGE_FORMAT)
  ));
  const answer = question.answer;
  /* let answer =
    question.answer == null
      ? null
      : checkDate(
          `dependency(${question.code}).value`,
          question.answer,
          minDate
        ); */
  const helperText =
    minDate === maxDate
      ? `You can only enter the following date: ${dayjs(question.min).format(
          displayDateFormat
        )}.`
      : `Enter dates between ${dayjs(question.min).format(
          displayDateFormat
        )} and ${dayjs(question.max).format(displayDateFormat)}.`;

  // #endregion
  // #region Events
  const handleChange = (e) => {
    if (!e) return;

    // NOTE: target = The question.property to update
    const start = dayjs(e[0].$d)?.format(config.defaults.DATE_STORAGE_FORMAT);
    const end = dayjs(e[1].$d)?.format(config.defaults.DATE_STORAGE_FORMAT);
    /*     question.answer[target] = dayjs(e.$d).format(
      config.defaults.DATE_STORAGE_FORMAT
    ); */
    question.answer.start = start;
    question.answer.end = end;
    /*     // utils.log.event(
      `handleChange(e): question.answer[${target}] = ${answer[target]}`
    ); */
    // utils.log.event(
    //   `handleChange(e): question.answer(start: ${start}, end: ${end}})`
    // );
    updateQuestionAnswer(question, answer, preview, props.setQuestions);
  };
  const isQuestionCompleted = isCompleted(question);

  /*eslint-disable-next-line*/
  // const {isSmDown} = useMediaQuery();

  // #endregion

  return (
    <>
      <div
        key={props.index}
        style={{
          ...props.rowStyle,
          border: isQuestionCompleted
            ? "1px solid #3BDB41"
            : "1px solid #E9E9E9",
        }}
      >
        {!preview && (
          <div style={{ fontSize: "18px", fontWeight: "700" }}>
            {question?.code}
          </div>
        )}
        <div style={{ width: "100%", padding: preview ? "30px" : 0 }}>
          <QuestionHeader question={question}></QuestionHeader>
          <p>{helperText}</p>
          <RenderPicker
            question={question}
            minDate={minDate}
            maxDate={maxDate}
            handleChange={handleChange}
          ></RenderPicker>
        </div>
      </div>
    </>
  );
}

const RenderPicker = ({ question, minDate, maxDate, handleChange }) => {
  // TODO: 072023 Review to see if this can be refactored similar to TimeInputRange.PickerRange for consistency.
  // PURPOSE: Presents a date picker range with dates disabled that is outside of the question min/max.
  // #region Initialize
  const { RangePicker } = DatePickerAntD;
  const displayDateFormat = config.defaults.DATE_DISPLAY_FORMAT;
  //const answer = question.answer;
  const disabledDates = (currentDate) => {
    // PURPOSE: Return an array of disabled dates.
    const min = dayjs(minDate);
    const max = dayjs(maxDate);
    // Disable dates that are outside the desired range
    return currentDate.isBefore(min, "day") || currentDate.isAfter(max, "day");
  };

  const { isSmDown } = useMediaQuery();
  // #endregion
  // #region Events
  // #endregion
  return (
    <LocalizationProvider dateAdapter={AdapterDayjs}>
      <RangePicker
        disabledTime
        hideDisabledOptions
        format={displayDateFormat}
        //value={[answer.start ?? null, answer.end ?? null]}
        onChange={handleChange}
        allowClear={false}
        disabledDate={disabledDates}
        defaultPickerValue={[dayjs(minDate), dayjs(maxDate)]}
        getPopupContainer={(triggerNode) => {
          // PURPOSE:  Used to position time picker relative to the question.
          // utils.log.disabled(
          //   `dateInputRange.js - Render - getPopupContainer(${triggerNode})`,
          //   triggerNode.parentNode
          // );
          return triggerNode.parentNode;
        }}
        style={{
          width: isSmDown ? "100%" : "300px",
          height: "56px",
          background: "#F8F8F8",
        }}
      />
    </LocalizationProvider>
  );
};
// #region Dependency

export function isAnswered(dependency) {
  // PURPOSE: Determine if dependency is satisfied.
  if (dependency == null) {
    return true;
  }
  const {question} = dependency || {};
  const {answer} = question || {};
  if(!answer)return false;
  
  utils.log.info("^^ DateInputRange isAnswered",dependency);
  // *** Evaluate ***
  switch (dependency.comparator) {
    case "None":
      return handleComparatorNone(dependency);
    case "not":
      return handleComparatorEquality(dependency);
    case "equals":
    case "before":
    case "after":
    case "between":
      return handleComparatorPrepositions(dependency);
    case "weekday":
    case "weekend":
      return handleComparatorDaysOfWeek(dependency);
    case "length":
      return handleComparatorLength(dependency);
    default:
      throw new Error(
        `isAnswered(${dependency.question.code}) - Unknown comparator: ${dependency.comparator}`
      );
  }
}
// #region Comparators

function isAnswerInRange(answer, min, max) {
  if (!answer || !answer.start || !answer.end) return false;

  const { start, end } = answer;
  const startDate = dayjs(start);
  const endDate = dayjs(end);
  const minDate = dayjs(min);
  const maxDate = dayjs(max);

  const isStartInRange = startDate.isAfter(minDate) || startDate.isSame(minDate);
  const isEndInRange = endDate.isBefore(maxDate) || endDate.isSame(maxDate);
  return isStartInRange && isEndInRange;
}

function handleComparatorNone(dependency){
  const {question} = dependency || {};
  const {min , max , answer} = question;
  return isAnswerInRange(answer , min , max);
}
function handleComparatorEquality(dependency) {
  const { question, comparator, value } = dependency || {};
  if (!question) return false;

  const { min, max, answer } = question;
  const isWithinRange = isAnswerInRange(answer, min, max);
  const answerStartDate = answer.start ? dayjs(answer.start) : null;
  const answerEndDate = answer.end ? dayjs(answer.end) : null;
  const valueDate = dayjs(value);

  switch (comparator) {
    case "equals":
      return (
        isWithinRange &&
        answerStartDate?.isSame(valueDate, "day") &&
        answerEndDate?.isSame(valueDate, "day")
      );
    case "not":
      return (
        isWithinRange &&
        (!answerStartDate?.isSame(valueDate, "day") ||
          !answerEndDate?.isSame(valueDate, "day"))
      );
    default:
      throw new Error(
        `handleComparatorEquality(${question.code}) - Unknown comparator: ${comparator}`
      );
  }
}

const handleComparatorPrepositions = (dependency) => {
  if (!dependency?.question) return false;

  const { question, comparator, value } = dependency;
  const { min, max, answer } = question;
  const isWithinRange = isAnswerInRange(answer, min, max);

  const answerStartDate = answer.start ? dayjs(answer.start) : null;
  const answerEndDate = answer.end ? dayjs(answer.end) : null;
  const valueDate = dayjs(value);

  const isSameOrBefore = (date, compareDate) =>
    date && (date.isBefore(compareDate) || date.isSame(compareDate, "day"));

  const isSameOrAfter = (date, compareDate) =>
    date && (date.isAfter(compareDate) || date.isSame(compareDate, "day"));

  switch (comparator) {
    case "before":
      return (
        isWithinRange &&
        isSameOrBefore(answerStartDate, valueDate) &&
        isSameOrBefore(answerEndDate, valueDate)
      );

    case "after":
      return (
        isWithinRange &&
        isSameOrAfter(answerStartDate, valueDate) &&
        isSameOrAfter(answerEndDate, valueDate)
      );

    case "between": {
      const minValue = dayjs(value.min);
      const maxValue = dayjs(value.max);

      const isStartBetween =
        isSameOrAfter(answerStartDate, minValue) &&
        isSameOrBefore(answerStartDate, maxValue);

      const isEndBetween =
        isSameOrAfter(answerEndDate, minValue) &&
        isSameOrBefore(answerEndDate, maxValue);

      return isWithinRange && isStartBetween && isEndBetween;
    }

    case "equals":
      return (
        isWithinRange &&
        answerStartDate?.isSame(dayjs(value?.min), "day") &&
        answerEndDate?.isSame(dayjs(value?.max), "day")
      );

    default:
      throw new Error(
        `Unknown comparator '${comparator}' for question '${question.code}'`
      );
  }
};

const isWeekday = (date) => {
  const day = date.day(); // 0 = Sunday, 6 = Saturday
  return day > 0 && day < 6; // Monday to Friday
};

const isWeekend = (date) => !isWeekday(date);

function handleComparatorDaysOfWeek(dependency) {
  const { question, comparator } = dependency || {};
  if (!question) return false;

  const { min, max, answer } = question;
  const validRange = isAnswerInRange(answer, min, max);
  const { start, end } = answer;

  if (!start || !end || !validRange) {
    return false;
  }

  const answerDateStart = dayjs(start);
  const answerDateEnd = dayjs(end);

  // Iterate through the range of dates
  const isAnyDateValid = (checkFn) => {
    let currentDate = answerDateStart;

    while (currentDate.isBefore(answerDateEnd) || currentDate.isSame(answerDateEnd, "day")) {
      if (checkFn(currentDate)) {
        return true;
      }
      currentDate = currentDate.add(1, "day");
    }
    return false;
  };

  switch (comparator) {
    case "weekday":
      return isAnyDateValid(isWeekday);

    case "weekend":
      return isAnyDateValid(isWeekend);

    default:
      throw new Error(
        `handleComparatorDaysOfWeek(${question.code}) - Unknown comparator: ${comparator}`
      );
  }
}



const handleComparatorLength = (dependency) => {
  const answer = dependency.question.answer;
  const min = dependency.value.min;
  const max = dependency.value.max;
  const length = answer.end.diff(answer.start, "days");
  return length >= min && length <= max;
};

export const isCompleted = (question) => {
  if (!question) {
    return false;
  }

  if (!question?.answer?.start || !question?.answer?.end) return false;

  const {
    answer: { start, end },
    min,
    max,
  } = question;

  const dayjsStart = dayjs(start);
  const dayjsEnd = dayjs(end);

  if (dayjsStart.isBefore(min) || dayjsEnd.isAfter(max)) return false;

  return true;
};

export const getValue = (question) => {
  return {
    start: question.answer.start,
    end: question.answer.end,
  };
};



// const handleComparatorEquality = (dependency) => {
//   const start = dayjs(dependency.question.answer.start);
//   const end = dayjs(dependency.question.answer.end);
//   const value = dayjs(dependency.value);

//   const resultStartIsSame = value.isSame(start, "day");
//   const resultEndIsSame = value.isSame(end, "day");
//   switch (dependency.comparator) {
//     case "equals":
//       return resultStartIsSame && resultEndIsSame;
//     case "not":
//       return !(resultStartIsSame && resultEndIsSame);
//     default:
//       throw new Error(
//         `handleComparatorEquality(${dependency.question.code}) - Unknown comparator: ${dependency.comparator}`
//       );
//   }
// };
// const handleComparatorPrepositions = (dependency) => {
//   if (
//     dependency.question.answer.start === undefined ||
//     dependency.question.answer.end === undefined
//   )
//     return false;
//   const start = dayjs(dependency.question.answer.start);
//   const end = dayjs(dependency.question.answer.end);

//   const valueMin = dayjs(dependency.value.min);
//   const valueMax = dayjs(dependency.value.max);

//   switch (dependency.comparator) {
//     case "before":
//       const resultBefore = start.isBefore(valueMin) && end.isBefore(valueMax);
//       return resultBefore;
//     case "after":
//       const resultAfter = start.isAfter(valueMin) && end.isAfter(valueMax);
//       return resultAfter;
//     case "between":
//       const isAfterMin =
//         start.isAfter(valueMin) || start.isSame(valueMin, "day");
//       const isBeforeMax =
//         start.isBefore(valueMax) || start.isSame(valueMax, "day");
//       const resultBetween = isAfterMin && isBeforeMax;
//       return resultBetween;
//     default:
//       throw new Error(
//         `handleComparatorPrepositions(${dependency.question.code}) - Unknown comparator: ${dependency.comparator}`
//       );
//   }
// };


// const handleComparatorDaysOfWeek = (dependency) => {
//   if (
//     dependency.question.answer.start === undefined ||
//     dependency.question.answer.end === undefined
//   )
//     return false;
//   const dayOfWeekStart = dayjs(dependency.question.answer.end).day();
//   const dayOfWeekEnd = dayjs(dependency.question.answer.end).day();
//   switch (dependency.comparator) {
//     case "weekday":
//       return (
//         dayOfWeekStart >= 1 &&
//         dayOfWeekStart <= 5 &&
//         dayOfWeekEnd >= 1 &&
//         dayOfWeekEnd <= 5
//       );
//     case "weekend":
//       return (
//         (dayOfWeekStart === 0 || dayOfWeekStart === 6) &&
//         (dayOfWeekEnd === 0 || dayOfWeekEnd === 6)
//       );
//     default:
//       throw new Error(
//         `handleComparatorDaysOfWeek(${dependency.question.code}) - Unknown comparator: ${dependency.comparator}`
//       );
//   }
// };


// #endregion
// #endregion
