import frame1Img from "../assets/Frame1.svg";
import frame2Img from "../assets/Frame2.png";
import desktopIcon from "../assets/desktopIcon.svg";
import phoneIcon from "../assets/phoneIcon.svg";
import emailIcon from "../assets/emailIcon.svg";
import logo from "../assets/footerLogo.svg";
import compassIconcon from "../assets/compassIcon.svg";
import instagramIcon from "../assets/instagramIcon.svg";
import facebookIcon from "../assets/facebookIcon.svg";
import twitterIcon from "../assets/twitterIcon.svg";

import { Link } from "react-router-dom";
import HomeIcon from "../assets/Home.svg";
import ContactIcon from "../assets/contact.svg";
import FeaturesIcon from "../assets/Features.svg";
import BlogIcon from "../assets/blog.svg";
import PriceIcon from "../assets/Price.svg";

import SingleChoice from "../assets/Single choice.svg";
import MultipleChoice from "../assets/Multiple.svg";
import Category from "../assets/Categoty.svg";
import Numeric from "../assets/Number.svg";
import Date from "../assets/Date.svg";
import DateRange from "../assets/Range.svg"
import Time from "../assets/Time.svg"
import TimeRange from "../assets/Time range.svg"
import TextInput from "../assets/text.svg"


export const contentData = [
  {
    title: "Configure other identity providers",
    link: "https://auth0.com/docs/connections",
    description:
      "Auth0 supports social providers as Facebook, Twitter, Instagram and 100+, Enterprise providers as Microsoft Office 365, Google Apps, Azure, and more. You can also use any OAuth2 Authorization Server.",
  },
  {
    title: "Enable Multifactor Authentication",
    link: "https://auth0.com/docs/multifactor-authentication",
    description:
      "Add an extra layer of security by enabling Multi-factor Authentication, requiring your users to provide more than one piece of identifying information. Push notifications, authenticator apps, SMS, and DUO Security are supported.",
  },
  {
    title: "Anomaly Detection",
    link: "https://auth0.com/docs/anomaly-detection",
    description:
      "Auth0 can detect anomalies and stop malicious attempts to access your application. Anomaly detection can alert you and your users of suspicious activity, as well as block further login attempts.",
  },
  {
    title: "Learn About Rules",
    link: "https://auth0.com/docs/rules",
    description:
      "Rules are JavaScript functions that execute when a user authenticates to your application. They run once the authentication process is complete, and you can use them to customize and extend Auth0's capabilities.",
  },
];

export const PRICING_DETAILS = [
  {
    heading: "Starter",
    description:
      "Lorem Ipsum is simply dummy text of the printing. Lorem Ipsum is simply dummy text.",
    price: "$15",
    btnText: "Choose Plan",
    services: [
      { service: "1 survey" },
      { service: "50 recipients" },
      { service: "1 user" },
      { service: "Basic Reports" },
      { service: "30 day data retention" },
    ],
  },
  {
    heading: "Professional",
    description:
      "Lorem Ipsum is simply dummy text of the printing. Lorem Ipsum is simply dummy text.",
    price: "$89.99",
    btnText: "Choose Plan",
    services: [
      { service: "Unlimited survey" },
      { service: "Unlimited recipients" },
      { service: "Unlimited users" },
      { service: "Basic Reports" },
      { service: "30 day data retention" },
      { service: "Advanced Reports" },
      { service: "Data retention while subscribed" },
    ],
  },
  {
    heading: "Enterprise",
    description:
      "Lorem Ipsum is simply dummy text of the printing. Lorem Ipsum is simply dummy text.",
    price: "Custom Price ",
    btnText: "Contact us",
    services: [
      { service: "Unlimited survey" },
      { service: "Unlimited recipients" },
      { service: "Unlimited users" },
      { service: "Basic Reports" },
      { service: "30 day data retention" },
      { service: "Advanced Reports" },
      { service: "Custom Reports" },
      { service: "Data retention while subscribed" },
    ],
  },
];

export const GET_IN_TOUCH_DETAILS = [
  {
    icon: emailIcon,
    description: "Sometimes features require a short description",
    heading: "24x5 Support over mail",
  },
  {
    icon: compassIconcon,
    description: "Sometimes features require a short description",
    heading: "24x5 Support over mail",
  },
  {
    icon: phoneIcon,
    description: "Sometimes features require a short description",
    heading: "24x5 Support over mail",
  },
  {
    icon: desktopIcon,
    description: "Sometimes features require a short description",
    heading: "24x5 Support over mail",
  },
];
export const CONTACTUS_INFO = {
  email: "support@commutersurvey.net",
  phone: "+1 (480) 485-4033",
};

export const FOOTER_LINKS = [
  {
    title: "COMPANY",
    links: [
      {
        subtitle: "Home",
        link: "/",
      },
      {
        subtitle: "Pricing",
        link: "/pricing",
      },
      {
        subtitle: "Features",
        link: "/features",
      },
      {
        subtitle: "Blog",
        link: "/blog",
      },
    ],
  },
  {
    title: "MEET US",
    links: [
      {
        subtitle: CONTACTUS_INFO?.phone,
        link: `tel:${CONTACTUS_INFO?.phone}`,
      },
      {
        subtitle: CONTACTUS_INFO?.email,
        link: `mailto:${CONTACTUS_INFO?.email}`,
      },
      {
        subtitle: "205. R Street, New York BD23200",
        link: "/features",
      },
    ],
  },
];

export const FOOTER_SOCIAL_LINKS = [
  {
    img: logo,
    description: (
      <>
        The Commuter Survey Tool (CST) is a purpose built survey management
        system for the TDM community powered by{" "}
        <Link to="https://www.gopassgo.net/">GoPassGo Networks, LLC</Link>
      </>
    ),
    social_links: [
      {
        link: "facebook.com",
        icon: facebookIcon,
      },
      {
        link: "twitter.com",
        icon: twitterIcon,
      },
      {
        link: "instagram.com",
        icon: instagramIcon,
      },
    ],
  },
];

export const NAVBAR = {
  links: [
    {
      title: "Home",
      link: "/",
      icon: <img src={HomeIcon} alt="Home-login" />,
    },
    {
      title: "Features",
      link: "/features",
      icon: <img src={FeaturesIcon} alt="feature-login" />,
    },
    {
      title: "Use Case",
      link: "/use-case",
    },
    {
      title: "Pricing",
      link: "/pricing",
      icon: <img src={PriceIcon} alt="price-login" />,
    },
    {
      title: "Blog",
      link: "/blog",
      icon: <img src={BlogIcon} alt="blog-login" />,
    },
    {
      title: "Contact Us",
      link: "/contact",
      icon: <img src={ContactIcon} alt="contact-login" />,
    },
  ],
};

export const HOMEPAGE_CONTENT = {
  hero: {
    title: `Commuter Survey Tool That works for you.`,
    description: (
      <>
        The Commuter Survey Tool (CST) is a purpose built survey management
        system for the TDM community powered by{" "}
        <Link to="https://www.gopassgo.net/">GoPassGo Networks, LLC</Link>
      </>
    ),
  },
  quickGlance: {
    title: "How GoPass helps to run effective Commuter Surveys!",
    features: [
      {
        title: "Excemptions",
        description:
          "Lorem ipsum dolor sit amet, consectetuer adipiscing elit. Aenean commodo ligula eget dolor.",
      },
      {
        title: "Follow ups & Reminders",
        description:
          "Lorem ipsum dolor sit amet, consectetuer adipiscing elit. Aenean commodo ligula eget dolor.",
      },
      {
        title: "Primary & Secondary Coordinators",
        description:
          "Lorem ipsum dolor sit amet, consectetuer adipiscing elit. Aenean commodo ligula eget dolor.",
      },
      {
        title: "Title Goes Here",
        description:
          "Lorem ipsum dolor sit amet, consectetuer adipiscing elit. Aenean commodo ligula eget dolor.",
      },
    ],
  },
  features: [
    {
      title: "Analytics to track the survey which was never before !",
      mainImg: frame1Img,
      description:
        "Our Commuter Survey Tool allows you to reduce guesswork with complete data visibility. Whether you are building a simple questionnaire, looking for a Google Forms or Doodle alternative, or managing a sophisticated survey with complex validation, conditional logic, and quote management, you can maximise your insights and quickly achieve your survey goals with ease.",
    },
    {
      title:
        "Most customisable survey tools to adapt your organisation with ease",
      description:
        "Enable your teams with the insights they need to invest in the right tools and initiatives. Align teams, tools, and workflows to streamline program launches and ensure every effort maximises impact, saving both time and money along the way.",
      mainImg: frame2Img,
    },
    {
      title: "There's no better way to reach your collegues",
      description:
        "From boosting employee engagement and streamlining data collection to automating rewards and seamlessly integrating with payroll and other systems, our tool ensures your initiatives deliver peak performance and measurable impact.",
      mainImg: frame1Img,
    },
  ],
  cards: [
    {
      link: "#",
      title: "Exemptions",
      description:
        "Manage exemptions easily by specifying which employees or groups are excluded from the survey and ensure targeted and accurate data collection",
    },
    {
      link: "#",
      title: "Follow ups & Reminders",
      description:
        "Enhance response rates with automated follow-ups and reminders, which prompt participants to complete surveys and give valuable feedback on time.",
    },
    {
      link: "#",
      title: "Primary Coordinators",
      description:
        "Assign primary coordinators to oversee survey management, track progress, and ensure smooth execution of all survey-related activities. ",
    },
  ],
};

export const questionTypes = [
  {
    type: "SingleChoice",
    icon: SingleChoice,
  },
  {
    type: "MultipleChoice",
    icon: MultipleChoice,
  },
  {
    type: "CategoryChoice",
    icon: Category,
  },
  {
    type: "TextInput",
    icon: TextInput,
  },
  {
    type: "NumericInput",
    icon: Numeric,
  },
  {
    type: "NumericInputRange",
    icon: Numeric,
  },
  {
    type: "DateInput",
    icon: Date,
  },
  {
    type: "DateInputRange",
    icon: DateRange,
  },
  {
    type: "TimeInput",
    icon: Time,
  },
  {
    type: "TimeInputRange",
    icon: TimeRange,
  },
];

export const TinyMCEEditorPlugins = [
  "advlist autolink lists link image charmap print preview anchor",
  "searchreplace visualblocks code fullscreen",
  "insertdatetime media table paste code help wordcount textcolor",
]

export const TinyMCEEditorToolbar ="undo redo | formatselect | image | bold italic | forecolor backcolor | alignleft aligncenter alignright alignjustify | bullist numlist outdent indent | removeformat | help";

export const TinyMCEEditorContentStyle = "body { font-family:Helvetica,Arial,sans-serif; font-size:14px; }"